#container {
  background: transparent;
}
#stampContainer {
  margin-left: 10px;
  /* margin-bottom: 20px; */
  /* margin: 0 0 40px 0; */
  /* padding: 0 0 0 13px; */
  /* background: transparent; */
}
.stampList {
  display: block;
  margin: 2px 0 0 0;
}

.stampsButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #fbc61e;
  border-radius: 30px;
  text-align: left;
  font-size: 18px;
  color: black;
}

.stampImage {
  margin-right: 10px;
}

.stampButton {
}

.stampLi button img {
  padding-right: 4px;
}

.MuiPopover-paper {
  background: transparent !important;
  padding-bottom: 20px !important;
  margin-bottom: 120px !important;
}
.MuiPaper-elevation8 {
  box-shadow: none !important;
}
