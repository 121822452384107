header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
  padding: 5px 20px 0;
  box-sizing: border-box;
}

#headerTitle {
  position: relative;
  padding: 2px 0 0 0;
}

#headerTitle h1 {
  display: inline-block;
}

#headerTitle .titleBox {
  position: absolute;
  left: 84px;
  top: 6px;
}

#stepName {
  display: inline;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 20px;
  color: #fbc61e;
  padding: 12px 0 2px 0;
}

#playTitle {
  display: inline;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 14px;
  color: #fbc61e;
  padding: 12px 0 2px 0;
}

#play {
  display: inline;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 12px;
  color: #fbc61e;
  padding: 12px 0 2px 0;
}

#subject {
  display: block;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 30px;
  color: #fff;
}

#mockController {
  position: absolute;
  top: 38px;
  right: 330px;
  vertical-align: middle;
}

#episodeContainer {
  position: absolute;
  top: 3px;
  right: 330px;
  vertical-align: middle;
}

#UtilBox {
  position: absolute;
  right: 16px;
  bottom: 5px;
}

#UtilBox ul {
  float: right;
}

#UtilBox ul:after {
  content: '';
  display: block;
  clear: both;
}
#UtilBox ul li {
  float: left;
  margin: 0 0 0 0;
}

#UtilBox ul li.time {
  font-family: 'Courier Prime', monospace;
  font-size: 38px;
  color: #fbc61e;
  line-height: 36px;
  padding: 0 8px 0 0;
}

#sirenContainer {
  background-color: #f9f9f9;
  padding: 20px;
  font-size: 24px;
  border-radius: 5px;
}

#sirenTitle {
  font-size: 14px;
  margin-bottom: 10px;
}

#btnContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#siren {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  /* background-color: #fbc61e; */
}
