.popWrap {
  position: fixed;
  left: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
}
.popWrap .guTop {
  position: relative;
  max-width: 470px;
  height: auto;
  margin: 0 auto;
}
.popWrap .guHead {
  width: 100%;
  height: 45px;
  overflow: hidden;
}
.popWrap .guHead .guClosed {
  position: absolute;
  right: -5px;
  top: 5px;
  z-index: 100;
}
.popWrap .guHead .guClosed a {
  display: block;
}

.popWrap .guCont {
  position: relative;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.popWrap .guCont .innerBox {
  padding: 24px;
}
.popWrap .guCont .innerBox:after {
  content: '';
  display: block;
  clear: both;
}
.popWrap .guCont .innerBox .tit {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -1px;
  padding: 2px 0 10px 0;
  border-bottom: 2px #000 solid;
}
.popWrap .guCont .innerBox .testDiv {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.popWrap .guCont .innerBox .testDiv .tDtop {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.popWrap .guCont .innerBox .testDiv .tDtop .ico {
  position: absolute;
  left: 0;
  bottom: 0;
}
.popWrap .guCont .innerBox .testDiv .tDtop .test {
  margin: 0 125px 0 90px;
}
.popWrap .guCont .innerBox .testDiv .tDtop .test:after {
  content: '';
  display: block;
  clear: both;
}
.popWrap .guCont .innerBox .testDiv .tDtop .test p {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -1px;
  padding: 26px 0 10px 0;
  word-break: break-word;
}
.popWrap .guCont .innerBox .testDiv .tDtop .test .search {
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
  overflow: hidden;
}
.popWrap .guCont .innerBox .testDiv .tDtop .cameraArea {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 107px;
  border-radius: 3px;
  overflow: hidden;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul {
  float: right;
  height: 78px;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li {
  position: relative;
  float: left;
  width: 11px;
  height: 100%;
  margin: 0 0 0 9px;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li span {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #999;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li:nth-child(1) {
  margin: 0;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li:nth-child(1) span {
  height: 55%;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li:nth-child(2) span {
  height: 65%;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li:nth-child(3) span {
  height: 75%;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li:nth-child(4) span {
  height: 85%;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea ul li:nth-child(5) span {
  height: 100%;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea .base {
  position: relative;
  width: 91px;
  overflow: hidden;
  z-index: 2;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea .dummy {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 91px;
  height: 78px;
  overflow: hidden;
  z-index: 4;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea .dummy ul {
  float: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 91px;
}
.popWrap .guCont .innerBox .testDiv .tDtop .volumeArea .dummy li span {
  background: #fbc61e;
}
.popWrap .guCont .innerBox .testDiv .tDbom {
  width: 100%;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
  padding: 15px 0 35px 0;
  border-top: 1px #efefef solid;
  border-bottom: 1px #fbc61e solid;
  overflow: hidden;
}
.popWrap .guCont .innerBox select {
  width: 100%;
  height: 33px;
}

.popWrap .setNoti {
  width: 100%;
  margin: 20px 0 0 0;
  background: #fbc61e;
  text-align: center;
  overflow: hidden;
}
.popWrap .setNoti button {
  font-size: 18px;
  color: #fff;
  line-height: 50px;
  letter-spacing: -1px;
  padding: 0 50px 0 50px;
}

.MuiSvgIcon-fontSizeLarge {
  font-size: 4rem !important;
}

.line {
  width: 100%;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
  padding: 15px 0 3px 0;
  /* border-top: 1px #efefef solid; */
  border-bottom: 1px #fbc61e solid;
  overflow: hidden;
}

.settingSiren {
  position: absolute;
  left: 20px;
  top: 26px;
}
