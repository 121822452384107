.container {
  background-color: #07416c;
  border-radius: 10px;
  padding: 2px 6px 2px 6px;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: gray; */
  /* margin-bottom: 10px; */
}
.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: blue;
}
.songName {
  font-size: 15;
  color: white;
  padding-left: 10px;
}
