.directionBox {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.dirBtn {
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 20;
}

.directionBox .dirBox {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
}
.dirInner {
  width: 80%;
  padding: 37px 0;
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0px 3px;
  border-radius: 10px 10px 0 0;
  margin: 0 auto;
  opacity: 1;
  z-index: 999;
}
.dirInner p {
  font-size: 20px;
  color: #000;
  line-height: 1.3;
  letter-spacing: -1px;
  word-break: keep-all;
}

.phrase {
  font-size: 20px;
  color: #ffffff;
  line-height: 1.3;

  word-break: keep-all;
  text-align: left;
  padding: 0 20px 0 20px;
  font-weight: 600;
}
