#timer {
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 101px;
  height: 100px;
  text-align: center;
  background: url(../../assets/bg_stopwatch.png) left top no-repeat;
  z-index: 20;
}

#seconds {
  user-select: auto;
  display: inline-block;
  width: 100%;
  font-family: 'DSDIGI';
  font-size: 60px;
  color: #000;
  line-height: 1.2;
  padding: 18px 0 0 0;
}
