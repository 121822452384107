#popWrap .popBg {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1b1b1c;
  opacity: 0;
  overflow: hidden;
  z-index: 1;
}
#popWrap {
  position: fixed;
  left: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
}
#popWrap #guHead {
  width: 100%;
  height: 45px;
  overflow: hidden;
}
#popWrap #guHead .guClosed {
  position: absolute;
  right: -5px;
  top: 5px;
  z-index: 100;
}
#popWrap #guHead .guClosed a {
  display: block;
  width: 40px;
  height: 40px;
  /* background: url('/images/btn_pop_close.png') left top no-repeat; */
}
#popWrap .guCont {
  position: relative;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
#popWrap .guCont .innerBox {
  padding: 24px;
}
#popWrap .guCont .innerBox:after {
  content: '';
  display: block;
  clear: both;
}
#popWrap .guCont .innerBox .tit {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  line-height: 1.2;
  padding: 2px 0 10px 0;
  border-bottom: 2px #000 solid;
}
#popWrap .guCont .innerBox .tit.noLine {
  border: 0;
}

#popWrap #guTBase {
  position: relative;
  max-width: 458px;
  height: auto;
  margin: 0 auto;
  z-index: 2;
} /* base */
#popWrap #guTBLow {
  position: relative;
  max-width: 470px;
  height: auto;
  margin: 0 auto;
  z-index: 2;
}
#popWrap #guTMLow {
  position: relative;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
  z-index: 2;
}
#popWrap #guTMid {
  position: relative;
  max-width: 640px;
  height: auto;
  margin: 0 auto;
  z-index: 2;
} /* middle */
#popWrap #guTWidth {
  position: relative;
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  z-index: 2;
} /* width */

/* tips */
#popWrap .tipsWrap {
  width: 100%;
  overflow: hidden;
}
#popWrap .tipsWrap .tipBox {
  position: relative;
  width: 100%;
  padding: 12px 0 15px 90px;
  border-bottom: 1px #c2c2c2 solid;
  box-sizing: border-box;
  overflow: hidden;
}
#popWrap .tipsWrap .tipBox .number {
  position: absolute;
  left: 0;
  top: 12px;
  width: 70px;
  height: 70px;
  font-size: 48px;
  color: #fff;
  font-weight: 600;
  line-height: 68px;
  text-align: center;
  background: #fbc61e;
  border-radius: 25px;
}
#popWrap .tipsWrap .tipBox .tipCont:after {
  content: '';
  display: block;
  clear: both;
}
#popWrap .tipsWrap .tipBox .tipCont p {
  font-size: 14px;
  color: #000;
  line-height: 1.3;
  font-weight: 600;
  padding: 7px 0 14px 0;
  word-break: keep-all;
}
#popWrap .tipsWrap .tipBox .tipCont ul:after {
  content: '';
  display: block;
  clear: both;
}
#popWrap .tipsWrap .tipBox .tipCont ul li {
  position: relative;
  display: block;
  padding: 0 0 0 18px;
  font-size: 14px;
  color: #000;
  line-height: 1.5;
  word-break: keep-all;
}
#popWrap .tipsWrap .tipBox .tipCont ul li span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  color: #000;
  line-height: 1.5;
  font-weight: 800;
}
#popWrap .tipsWrap .tipBox:last-child {
  border: 0;
  padding: 12px 0 20px 90px;
}
