#timeDiv {
  /* position: absolute; */
  left: 50%;
  bottom: 0;
  margin: 0 0 0 -20px;
  z-index: 10;
  display: block;
}

.stampLi {
  display: block;
  margin: 5px 0 0 0;
}

.stampLi button {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background: #fbc61e;
  border-radius: 100%;
  text-align: center;
}
