#imageCanvas {
  position: absolute;
  top: 200;
  left: 0;
  z-index: 1;
  /* opacity: 0.5; */
}

#drawCanvas {
  position: absolute;
  top: 100;
  left: 0;
  z-index: 2;
  /* opacity: 0.3; */
}

#book {
  margin: 0 auto;
}
